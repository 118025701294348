import { styled } from '@mui/material';
import React from 'react';
import pic from '/static/start1.webp';
import useFetchImage from 'hooks/useFetchImages';
import useDynamicTheme from 'hooks/useDynamicTheme';
import JumbotronButtons from './JumbotronButtons';

interface ImageContainerProps {
  src: string;
}

const ImageContainer = styled('div')<ImageContainerProps>(({ theme, src }) => ({
  position: 'relative',
  width: '100%',
  backgroundImage: `url(${src})`,
  height: '530px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 50%',
  [theme.breakpoints.down('md')]: {
    height: '480px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '430px',
  },
}));

const Jumbotron: React.FC = () => {
  const theme = useDynamicTheme();
  const imageUrl = useFetchImage('LANDING', pic);
  return (
    <ImageContainer src={imageUrl}>
      <JumbotronButtons />
    </ImageContainer>
  );
};

export default Jumbotron;
